<template>
  <ol class="flex flex-wrap text-gray-700 text-xs" v-if="breadcrumbs">
    <li class="px-2" v-for="item in breadcrumbs" :key="item.href">
      <template v-if="!item.current">
        <router-link class="hover:underline" :to="{ path: item.href }">
          {{ item.name }}
        </router-link>
        <span class="text-gray-500 select-none ml-4">/</span>
      </template>
      <p v-else class="text-yellow-600">{{ item.name }}</p>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
  },

  computed: {
    breadcrumbs() {
      if (typeof this.$route.meta.breadcrumbs === "function") {
        return this.$route.meta.breadcrumbs.call(this, this.$route);
      }

      return this.$route.meta.breadcrumbs;
    },
  },
};
</script>

<style></style>
